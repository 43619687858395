import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  IconButton,
  Badge,
  Typography,
  Icon,
} from "@mui/material";
import { Person, ShoppingCart, WineBar } from "@mui/icons-material";
import useStyles from "./styles";
import { useAuth } from "../../contexts/AuthProvider";
import InfoBanner from "../InfoBanner/InfoBanner";

export const Navbar = () => {
  const { userCartQtyItems: cartQtyItems } = useAuth();
  const classes = useStyles();
  const [isColored, setIsColored] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 64) {
        setIsColored(true);
      } else {
        setIsColored(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const onClickMainMenu = () => {
    window.scroll(0, 0);
    navigate("home");
  };

  const onClickCart = () => {
    window.scroll(0, 0);
    navigate("cart");
  };

  const onClickAuth = () => {
    window.scroll(0, 0);
    navigate("auth");
  };

  const onClickShop = () => {
    window.scroll(0, 0);
    navigate("shop");
  };

  return (
    <AppBar
      position="fixed"
      id="appNavbar"
      className={isColored ? classes.appBarColored : classes.appBarTransparent}
      style={{
        backgroundColor: isColored ? "#0a0808" : "#0a0808",
        width: "100vw",
        left: "0",
      }}
    >
      {/* <InfoBanner
        title={"Christmas Sale | 10-12 Decembrie 2024 | Reduceri de neratat!"}
        subtitle={""}
      /> */}
      <Toolbar>
        <IconButton aria-label="Home" color="inherit" onClick={onClickMainMenu}>
          <img
            alt="Logo"
            className={classes.image}
            src={process.env.PUBLIC_URL + "/logo192.avif"}
          />
        </IconButton>
        <Typography
          style={{
            fontFamily: "Montserrat, sans-serif",
            color: "#bcab79",
            cursor: "pointer",
          }}
          onClick={onClickMainMenu}
        >
          Prosecco Shop
        </Typography>
        <div className={classes.grow} />
        <IconButton
          style={{ color: "#bcab79" }}
          title="Magazin"
          onClick={onClickShop}
        >
          <WineBar></WineBar>
          <br />
          <Typography
            style={{
              fontFamily: "Montserrat, sans-serif",
              color: "#bcab79",
              cursor: "pointer",
            }}
          >
            Shop
          </Typography>
        </IconButton>
        <IconButton aria-label="Profile" title="Cont" onClick={onClickAuth}>
          <Badge>
            <Icon component={Person} style={{ color: "#bcab79" }} />
          </Badge>
        </IconButton>
        <IconButton
          title="Cos"
          aria-label="Show cart items"
          style={{ color: "#bcab79" }}
          onClick={onClickCart}
        >
          <Badge badgeContent={cartQtyItems} color="secondary">
            <ShoppingCart />
          </Badge>
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};
